import gql from "graphql-tag";

let Store = {};
Store.fragments = {
    attributes: gql`
        fragment StoreFragment on Store {
            id
            name
            first_name
            last_name
            license
            email
            phone_number
            logo
            logo_small
            store_feature {
                id
                status
                name
                description
                position
            }
            account_setting {
                active
                auto_verify_email
                cognito_identity_pool_id
                cognito_region
                user_pools_id
                user_pools_web_client_id
            }
            aws_pinpoint_analytics {
                active
                region
                app_id
                identity_pool_id
                auth_region
            }

            address1
            address2
            city
            country
            country_code
            country_name
            province
            province_code
            zip

            latitude
            longitude

            business_slogan
            business_description

            min_order_amount

            gtm_active
            gtm_id

            domain

            instagram
            facebook
            twitter
            yelp
            youtube

            delivery {
                active
                price
            }

            timezone
            money_format
            currency

            created_at
            updated_at

            tax_active
            tax_type
            tax_ammont

            s3_bucket
            algolia_order_index
            dynamo_order_table_name
            aws_profile
            aws_region

            status
            medias

            pickup_hours {
                day
                period {
                    to
                    from
                }
            }
            delivery_hours {
                day
                period {
                    to
                    from
                }
            }
            working_hours {
                day
                period {
                    to
                    from
                }
            }
            email_notification_active
            warning_setting

            debit_card_fee

            display_post_tax

            number_of_days_in_advance

            show_reward_at_checkout

            ach_payment_banner_url

            provider_deployment_url

            vercel_project_id

            agreements {
                reservation_mode
                content
                check_by_default
                required
                stores_provider_ids
            }

            payment_settings {
                reservation_mode
                payment_type
                stores_provider_ids
            }

            pickup_schedules {
                name
                note
                stores_provider_id
                time_slots {
                    start
                    end
                    cutoff_time
                    disable_for_today
                    disable
                    is_express
                    day_of_the_week
                }
                working_hours {
                    start
                    end
                    day_of_the_week
                }
            }
            delivery_schedules {
                name
                note
                zips
                min
                free_after
                delivery_fee
                stores_provider_id
                polygon_geo_zone
                radius
                geo_type

                time_slots {
                    start
                    end
                    is_express
                    cutoff_time
                    disable_for_today
                    disable
                    day_of_the_week
                }
                working_hours {
                    start
                    end
                    day_of_the_week
                }
                express_menu_config {
                    active
                    active_by_default
                    start
                    end
                    name
                    note
                    inventory_provider_ids
                }
            }
            feature_flags {
                wishlist_active
                is_grlimit_active
                keep_shopping_active
                disable_product_reviews
                show_product_warning
                show_striketrough_price
                is_add_to_cart_in_kiosk_disabled
                is_med_and_rec_selection_active
                treez_filter_sort
                allow_guest_checkout
                display_out_of_stock_products
                treez_filters_title_mapping
                discount_minimum_threshold_value
                kiosk_timeout_seconds
                kiosk_countdown_time
                kiosk_thank_you_timeout_seconds
                kiosk_first_name_required
                kiosk_last_name_required
                kiosk_email_required
                kiosk_phone_required
                kiosk_dob_required
            }
            tier_plan
            disabled_time_window
            prismic_api_endpoint
            google_map_api_url
            store_cache_version
        }
    `,
    domain: gql`
        fragment StoreDomainFragment on Store {
            domain
        }
    `,
};

export const QUERY_STORES_FROM_ACCOUNT_LIST = gql`
    query listStoresFromAccount($account_id: String!) {
        listStoresFromAccount(account_id: $account_id) {
            ...StoreFragment
        }
    }
    ${Store.fragments.attributes}
`;

export const QUERY_GET_STORE = gql`
    query getStore($account_id: String!, $store_id: String!) {
        getStore(account_id: $account_id, store_id: $store_id) {
            ...StoreFragment
        }
    }
    ${Store.fragments.attributes}
`;

export const QUERY_GET_STORE_DOMAIN = gql`
    query getStoreDomain($account_id: String!, $store_id: String!) {
        getStore(account_id: $account_id, store_id: $store_id) {
            ...StoreDomainFragment
        }
    }
    ${Store.fragments.domain}
`;

export const MUTATION_UPDATE_STORE = gql`
    mutation updateStore($account_id: String!, $input: StoreInput!) {
        updateStore(account_id: $account_id, input: $input) {
            ...StoreFragment
        }
    }
    ${Store.fragments.attributes}
`;

export const MUTATION_CREATE_STORE = gql`
    mutation createStore($account_id: String!, $input: StoreInput!) {
        createStore(account_id: $account_id, input: $input) {
            ...StoreFragment
        }
    }
    ${Store.fragments.attributes}
`;

export const MUTATION_CREATE_STORE_ASYNC = gql`
    mutation createStoreAsync($account_id: String!, $input: StoreInput!) {
        createStoreAsync(account_id: $account_id, input: $input)
    }
`;
